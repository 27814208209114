var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { attrs: { id: "terms_menu" } }, [
        _c("div", [
          _c(
            "button",
            { attrs: { id: "verify_menu" }, on: { click: _vm.verifyTerms } },
            [_vm._v("验证")]
          ),
        ]),
        _c("div", [
          _c(
            "button",
            { attrs: { id: "confirm_menu" }, on: { click: _vm.confirmTerms } },
            [_vm._v("确认")]
          ),
        ]),
      ]),
      _c(
        "a-spin",
        {
          attrs: { spinning: _vm.importLoading, tip: "导入中，请勿刷新页面.." },
          on: { click: _vm.hideContextMenu },
        },
        [
          _c(
            "div",
            {
              staticClass: "actions flex jc-end mb-4",
              staticStyle: { "flex-wrap": "wrap" },
            },
            [
              _c("div", { staticStyle: { flex: "1" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "a-row",
                      { attrs: { type: "flex", gutter: 16 } },
                      [
                        _c(
                          "a-col",
                          [
                            _c(
                              "a-select",
                              {
                                staticStyle: {
                                  width: "210px",
                                  position: "relative",
                                },
                                attrs: {
                                  allowClear: "",
                                  showArrow: "",
                                  mode: "multiple",
                                  placeholder: "请选择状态",
                                  maxTagCount: 1,
                                  "option-filter-prop": "label",
                                },
                                model: {
                                  value: _vm.searchStatus,
                                  callback: function ($$v) {
                                    _vm.searchStatus = $$v
                                  },
                                  expression: "searchStatus",
                                },
                              },
                              [
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "待确认" } },
                                  [_vm._v("待确认")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "已驳回" } },
                                  [_vm._v("已驳回")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "校对已驳回" } },
                                  [_vm._v("校对已驳回")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "终审已驳回" } },
                                  [_vm._v("终审已驳回")]
                                ),
                                _c(
                                  "a-select-option",
                                  { attrs: { value: "已确认" } },
                                  [_vm._v("已确认")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          [
                            _c(
                              "a-select",
                              {
                                staticStyle: { width: "200px" },
                                attrs: {
                                  allowClear: "",
                                  showArrow: "",
                                  mode: "multiple",
                                  placeholder: "请选择分类",
                                  "option-filter-prop": "label",
                                },
                                model: {
                                  value: _vm.termTypeName,
                                  callback: function ($$v) {
                                    _vm.termTypeName = $$v
                                  },
                                  expression: "termTypeName",
                                },
                              },
                              _vm._l(_vm.templateNameList, function (i) {
                                return _c(
                                  "a-select-option",
                                  { key: i.id, attrs: { value: i.name } },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(i.name) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          [
                            _c("a-input", {
                              attrs: { placeholder: "请输入原文名或译文名" },
                              on: { pressEnter: _vm.searchQuery },
                              model: {
                                value: _vm.searchText,
                                callback: function ($$v) {
                                  _vm.searchText = $$v
                                },
                                expression: "searchText",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          [
                            _c(
                              "a-button",
                              {
                                attrs: { type: "primary", icon: "search" },
                                on: { click: _vm.searchQuery },
                              },
                              [_vm._v("搜索")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _vm.selectedRowKeys.length
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "production:termtemplate:button:delete",
                          expression: "'production:termtemplate:button:delete'",
                        },
                      ],
                      staticClass: "mr-3",
                      attrs: { type: "danger", icon: "delete" },
                      on: { click: _vm.batchDel },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
              _c(
                "a-dropdown",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:termtemplate:button:export",
                      expression: "'production:termtemplate:button:export'",
                    },
                  ],
                },
                [
                  _c(
                    "a-menu",
                    { attrs: { slot: "overlay" }, slot: "overlay" },
                    [
                      _c("a-menu-item", { key: "1" }, [
                        _c("span", { on: { click: _vm.downloadTerm } }, [
                          _vm._v("下载导入模板"),
                        ]),
                      ]),
                      _c(
                        "a-menu-item",
                        { key: "2" },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                name: "file",
                                showUploadList: false,
                                multiple: false,
                                headers: {
                                  authorization: "authorization-text",
                                },
                                "before-upload": _vm.beforeUpload,
                              },
                              on: { change: _vm.handleImportExcel },
                            },
                            [_c("span", [_vm._v("选择文件导入")])]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-button",
                    { staticClass: "mr-3" },
                    [
                      _vm._v(" 导入 "),
                      _c("a-icon", { attrs: { type: "down" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:termtemplate:button:export",
                      expression: "'production:termtemplate:button:export'",
                    },
                  ],
                  staticClass: "mr-3",
                  on: { click: _vm.exportExcel },
                },
                [_vm._v("导出")]
              ),
              _vm.selectedRowKeys.length
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "production:termtemplate:button:delete",
                          expression: "'production:termtemplate:button:delete'",
                        },
                      ],
                      staticClass: "mr-3",
                      attrs: {
                        type: "danger",
                        disabled: _vm.selectionRows.some(
                          (item) => item.term.status.indexOf("已驳回") > -1
                        ),
                      },
                      on: { click: _vm.rejects },
                    },
                    [_vm._v("驳回")]
                  )
                : _vm._e(),
              _vm.selectedRowKeys.length
                ? _c(
                    "a-button",
                    {
                      directives: [
                        {
                          name: "has",
                          rawName: "v-has",
                          value: "production:termtemplate:button:delete",
                          expression: "'production:termtemplate:button:delete'",
                        },
                      ],
                      staticClass: "mr-3",
                      attrs: {
                        disabled: _vm.selectionRows.some(
                          (item) => item.term.status == "已确认"
                        ),
                      },
                      on: { click: _vm.sends },
                    },
                    [_vm._v("确认")]
                  )
                : _vm._e(),
              _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "production:termtemplate:button:add",
                      expression: "'production:termtemplate:button:add'",
                    },
                  ],
                  attrs: { type: "primary", icon: "plus" },
                  on: { click: _vm.openAddForm },
                },
                [_vm._v("添加")]
              ),
              _c("div", { staticStyle: { height: "32px" } }),
            ],
            1
          ),
          _c("a-table", {
            ref: "table",
            staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
            attrs: {
              size: "middle",
              id: "out-table",
              rowKey: (record) => record.term.id,
              columns: _vm.columns.filter(
                (c) =>
                  (c.dataIndex != "phonetic" &&
                    c.dataIndex != "phoneticStatus") ||
                  this.parent.departName == "日语部"
              ),
              dataSource: _vm.dataSource,
              customRow: _vm.clickRow,
              scroll: { x: 1000, y: 445 },
              pagination: _vm.ipagination,
              loading: _vm.loading,
              rowClassName: (record, index) => {
                if (record.checked && !record.confirmed) {
                  return "active_row"
                }
                return ""
              },
              rowSelection: {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                columnWidth: 40,
              },
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "termsTemplate.templateName",
                fn: function (text, record) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(record.term.termTypeName) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "korea-name",
                fn: function (text, record) {
                  return [
                    record.term &&
                    record.term.originName &&
                    record.term.originName.length > 14
                      ? _c(
                          "a-tooltip",
                          [
                            _c("template", { slot: "title" }, [
                              _c("div", [
                                record.term.termType == "人物"
                                  ? _c("span", [
                                      _c("div", [
                                        _vm._v(
                                          "姓：" +
                                            _vm._s(record.term.originFamilyName)
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "名：" +
                                            _vm._s(record.term.originGivenName)
                                        ),
                                      ]),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(record.term.originName)),
                                    ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  "white-space": "nowrap",
                                },
                              },
                              [
                                _c("div", [
                                  record.term.termType == "人物"
                                    ? _c("span", [
                                        _c("div", [
                                          _vm._v(
                                            "姓：" +
                                              _vm._s(
                                                record.term.originFamilyName
                                              )
                                          ),
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "名：" +
                                              _vm._s(
                                                record.term.originGivenName
                                              )
                                          ),
                                        ]),
                                      ])
                                    : _c("span", [
                                        _vm._v(_vm._s(record.term.originName)),
                                      ]),
                                ]),
                              ]
                            ),
                          ],
                          2
                        )
                      : _c("div", [
                          record.term.termType == "人物"
                            ? _c("span", [
                                _c("div", [
                                  _vm._v(
                                    "姓：" +
                                      _vm._s(record.term.originFamilyName)
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "名：" + _vm._s(record.term.originGivenName)
                                  ),
                                ]),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(record.term.originName)),
                              ]),
                        ]),
                  ]
                },
              },
              {
                key: "chinese-name",
                fn: function (text, record) {
                  return [
                    record.term &&
                    record.term.processedName &&
                    record.term.processedName.length > 14
                      ? _c(
                          "a-tooltip",
                          [
                            _c("template", { slot: "title" }, [
                              _c("div", [
                                record.term.termType == "人物"
                                  ? _c("span", [
                                      _c("div", [
                                        _vm._v(
                                          "姓：" +
                                            _vm._s(
                                              record.term.processedFamilyName
                                            )
                                        ),
                                      ]),
                                      _c("div", [
                                        _vm._v(
                                          "名：" +
                                            _vm._s(
                                              record.term.processedGivenName
                                            )
                                        ),
                                      ]),
                                    ])
                                  : _c("span", [
                                      _vm._v(_vm._s(record.term.processedName)),
                                    ]),
                              ]),
                            ]),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  overflow: "hidden",
                                  "text-overflow": "ellipsis",
                                  "white-space": "nowrap",
                                },
                              },
                              [
                                _c("div", [
                                  record.term.termType == "人物"
                                    ? _c("span", [
                                        _c("div", [
                                          _vm._v(
                                            "姓：" +
                                              _vm._s(
                                                record.term.processedFamilyName
                                              )
                                          ),
                                        ]),
                                        _c("div", [
                                          _vm._v(
                                            "名：" +
                                              _vm._s(
                                                record.term.processedGivenName
                                              )
                                          ),
                                        ]),
                                      ])
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(record.term.processedName)
                                        ),
                                      ]),
                                ]),
                              ]
                            ),
                          ],
                          2
                        )
                      : _c("div", [
                          record.term.termType == "人物"
                            ? _c("span", [
                                _c("div", [
                                  _vm._v(
                                    "姓：" +
                                      _vm._s(record.term.processedFamilyName)
                                  ),
                                ]),
                                _c("div", [
                                  _vm._v(
                                    "名：" +
                                      _vm._s(record.term.processedGivenName)
                                  ),
                                ]),
                              ])
                            : _c("span", [
                                _vm._v(_vm._s(record.term.processedName)),
                              ]),
                        ]),
                  ]
                },
              },
              {
                key: "first-login-time",
                fn: function (text, record) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(record.term.debut) + "\n      "
                    ),
                  ]
                },
              },
              {
                key: "phoneticStatus",
                fn: function (text, record) {
                  return [
                    record.term.phoneticStatus > 0 ||
                    record.term.phoneticStatus === 0
                      ? _c("div", [
                          _c(
                            "span",
                            [
                              record.term.phoneticStatus == 1
                                ? _c("a-icon", {
                                    staticStyle: { color: "green" },
                                    attrs: {
                                      type: "check-circle",
                                      theme: "filled",
                                    },
                                  })
                                : _c("a-icon", {
                                    staticStyle: { color: "red" },
                                    attrs: {
                                      type: "close-circle",
                                      theme: "filled",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _c(
                            "span",
                            {
                              style:
                                record.term.phoneticStatus == 1
                                  ? "color:green;margin-left:5px"
                                  : "color:red;margin-left:5px",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  record.term.phoneticStatus == 0
                                    ? "未注音"
                                    : record.term.phoneticStatus == 1
                                    ? "已注音"
                                    : record.term.phoneticStatus == 2
                                    ? "姓未注音"
                                    : record.term.phoneticStatus == 3
                                    ? "名未注音"
                                    : record.term.phoneticStatus == 4
                                    ? "每次注音"
                                    : record.term.phoneticStatus == 5
                                    ? "不需要注音"
                                    : ""
                                )
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "phonetic",
                fn: function (text, record) {
                  return [
                    _c("div", [
                      _c("div", [
                        record.term.phoneticTextList &&
                        record.term.phoneticTextList.length
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    record.term.phoneticTextList
                                      .map((p) => p.value)
                                      .join("")
                                  ) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        record.term.termType == "人物"
                          ? _c("div", [
                              record.term.phoneticFamilyNameTextList &&
                              record.term.phoneticFamilyNameTextList.length
                                ? _c("div", [
                                    _vm._v(
                                      "\n                姓：" +
                                        _vm._s(
                                          record.term.phoneticFamilyNameTextList
                                            .map((p) => p.value)
                                            .join("")
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                              record.term.phoneticGivenNameTextList &&
                              record.term.phoneticGivenNameTextList.length
                                ? _c("div", [
                                    _vm._v(
                                      "\n                名：" +
                                        _vm._s(
                                          record.term.phoneticGivenNameTextList
                                            .map((p) => p.value)
                                            .join("")
                                        ) +
                                        "\n              "
                                    ),
                                  ])
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                },
              },
              {
                key: "status",
                fn: function (text, record) {
                  return [
                    _vm._v(
                      "\n        " + _vm._s(record.term.status) + "\n      "
                    ),
                  ]
                },
              },
              {
                key: "rejectReason",
                fn: function (text, record) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(record.term.rejectReason) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "description",
                fn: function (text, record) {
                  return [
                    _vm._v(
                      "\n        " +
                        _vm._s(record.term.description) +
                        "\n      "
                    ),
                  ]
                },
              },
              {
                key: "gender",
                fn: function (text, record) {
                  return [
                    _c("span", { staticStyle: { "padding-left": "3px" } }, [
                      _vm._v(_vm._s(record.term.gender)),
                    ]),
                  ]
                },
              },
              {
                key: "identity",
                fn: function (text, record) {
                  return [
                    _c("div", { staticStyle: { "padding-left": "5px" } }, [
                      _vm._v(_vm._s(record.term.identity)),
                    ]),
                  ]
                },
              },
              {
                key: "call",
                fn: function (text, record) {
                  return [
                    _c("div", { staticStyle: { "padding-left": "5px" } }, [
                      _vm._v(_vm._s(record.term.call)),
                    ]),
                  ]
                },
              },
              {
                key: "avatar",
                fn: function (text, record) {
                  return [
                    record.term.termTypeName == "主角" ||
                    record.term.termTypeName == "配角" ||
                    record.term.termTypeName == "路人"
                      ? _c("div", { staticClass: "avatar" }, [
                          record.term.subjectImgUrl &&
                          record.term.subjectImgUrl[0]
                            ? _c("img", {
                                staticStyle: { width: "50px", height: "50px" },
                                attrs: {
                                  src: record.term.subjectImgUrl[0],
                                  alt: "avatar",
                                },
                              })
                            : _c("img", {
                                staticStyle: {
                                  width: "50px",
                                  height: "50px",
                                  "border-radius": "50%",
                                },
                                attrs: {
                                  src: require("./../../../assets/tx.png"),
                                  alt: "",
                                },
                              }),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "creator",
                fn: function (text, record) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          display: "flex",
                          "align-items": "center",
                        },
                      },
                      [
                        _c("div", { staticClass: "status-img" }, [
                          record.creator.avatar
                            ? _c("img", {
                                staticStyle: {
                                  width: "35px",
                                  height: "35px",
                                  "border-radius": "50%",
                                },
                                attrs: { src: record.creator.avatar, alt: "" },
                              })
                            : _c("img", {
                                staticStyle: {
                                  width: "35px",
                                  height: "35px",
                                  "border-radius": "50%",
                                },
                                attrs: {
                                  src: require("./../../../assets/tx.png"),
                                  alt: "",
                                },
                              }),
                        ]),
                        _c("span", [_vm._v(_vm._s(record.creator.realname))]),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "action",
                fn: function (text, record) {
                  return [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        record.term.status.indexOf("已驳回") == -1
                          ? _c(
                              "a",
                              {
                                staticStyle: { "margin-right": "15px" },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.reject(record)
                                  },
                                },
                              },
                              [_vm._v("驳回")]
                            )
                          : _vm._e(),
                        record.term.status != "已确认"
                          ? _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.send(record)
                                  },
                                },
                              },
                              [_vm._v("确认")]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("terms-modal", {
            attrs: {
              productionId: _vm.parent.id,
              parent: _vm.parent,
              terms: _vm.terms,
              open: _vm.drawerShow,
              params: _vm.params,
              "cur-row": _vm.curRow,
              isForm: _vm.isNewForm,
            },
            on: { changeCurrow: _vm.changeCurrow, closed: _vm.drawerClosed },
          }),
          _c(
            "a-modal",
            {
              attrs: { title: "驳回理由", visible: _vm.visible },
              on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
            },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入驳回理由" },
                model: {
                  value: _vm.rejectReason,
                  callback: function ($$v) {
                    _vm.rejectReason = $$v
                  },
                  expression: "rejectReason",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }