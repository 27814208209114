<!-- 作品详情术语一览 -->
<template>
  <div>
    <!-- {{ { parent } }} -->
    <div id="terms_menu">
      <div>
        <button id="verify_menu" @click="verifyTerms">验证</button>
      </div>
      <div>
        <button id="confirm_menu" @click="confirmTerms">确认</button>
      </div>
    </div>
    <a-spin :spinning="importLoading" tip="导入中，请勿刷新页面.." @click="hideContextMenu">
      <div class="actions flex jc-end mb-4" style="flex-wrap:wrap">
        <div style="flex:1">
          <div>
            <a-row type="flex" :gutter="16">
              <a-col>
                <a-select
                  allowClear
                  showArrow
                  mode="multiple"
                  placeholder="请选择状态"
                  v-model="searchStatus"
                  :maxTagCount="1"
                  option-filter-prop="label"
                  style="width: 210px; position: relative"
                >
                  <a-select-option value="待确认">待确认</a-select-option>
                  <a-select-option value="已驳回">已驳回</a-select-option>
                  <a-select-option value="校对已驳回">校对已驳回</a-select-option>
                  <a-select-option value="终审已驳回">终审已驳回</a-select-option>
                  <a-select-option value="已确认">已确认</a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-select
                  allowClear
                  showArrow
                  mode="multiple"
                  placeholder="请选择分类"
                  option-filter-prop="label"
                  style="width: 200px;"
                  v-model="termTypeName"
                >
                  <a-select-option v-for="i in templateNameList" :value="i.name" :key="i.id">
                    {{ i.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col>
                <a-input placeholder="请输入原文名或译文名" @pressEnter="searchQuery" v-model="searchText"></a-input>
              </a-col>
              <a-col>
                <a-button type="primary" icon="search" @click="searchQuery">搜索</a-button>
              </a-col>
            </a-row>
          </div>
        </div>
        <a-button
          type="danger"
          class="mr-3"
          @click="batchDel"
          icon="delete"
          v-if="selectedRowKeys.length"
          v-has="'production:termtemplate:button:delete'"
          >删除</a-button
        >
        <a-dropdown v-has="'production:termtemplate:button:export'">
          <a-menu slot="overlay">
            <a-menu-item key="1">
              <span @click="downloadTerm">下载导入模板</span>
            </a-menu-item>
            <a-menu-item key="2">
              <a-upload
                name="file"
                :showUploadList="false"
                :multiple="false"
                :headers="{
                  authorization: 'authorization-text'
                }"
                :before-upload="beforeUpload"
                @change="handleImportExcel"
              >
                <span>选择文件导入</span>
              </a-upload></a-menu-item
            >
          </a-menu>
          <a-button class="mr-3"> 导入 <a-icon type="down"/></a-button>
        </a-dropdown>
        <a-button class="mr-3" v-has="'production:termtemplate:button:export'" @click="exportExcel">导出</a-button>
        <a-button
          type="danger"
          class="mr-3"
          @click="rejects"
          :disabled="selectionRows.some(item => item.term.status.indexOf('已驳回') > -1)"
          v-if="selectedRowKeys.length"
          v-has="'production:termtemplate:button:delete'"
          >驳回</a-button
        >
        <a-button
          class="mr-3"
          @click="sends"
          :disabled="selectionRows.some(item => item.term.status == '已确认')"
          v-if="selectedRowKeys.length"
          v-has="'production:termtemplate:button:delete'"
          >确认</a-button
        >
        <!--         <a-button
          type="primary"
          @click="newTerms"
          icon="plus"
          v-has="'production:termtemplate:button:add'"
        >添加</a-button> -->
        <a-button type="primary" @click="openAddForm" icon="plus" v-has="'production:termtemplate:button:add'"
          >添加</a-button
        >
        <div style="height: 32px"></div>
      </div>
      <a-table
        style="border-top:1px solid rgb(232,232,232)"
        ref="table"
        size="middle"
        id="out-table"
        :rowKey="record => record.term.id"
        :columns="
          columns.filter(
            c => (c.dataIndex != 'phonetic' && c.dataIndex != 'phoneticStatus') || this.parent.departName == '日语部'
          )
        "
        :dataSource="dataSource"
        :customRow="clickRow"
        :scroll="{ x: 1000, y: 445 }"
        :pagination="ipagination"
        :loading="loading"
        :rowClassName="
          (record, index) => {
            if (record.checked && !record.confirmed) {
              return 'active_row'
            }
            return ''
          }
        "
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange, columnWidth: 40 }"
        @change="handleTableChange"
      >
        <template slot="termsTemplate.templateName" slot-scope="text, record">
          {{ record.term.termTypeName }}
        </template>
        <template slot="korea-name" slot-scope="text, record">
          <a-tooltip v-if="record.term && record.term.originName && record.term.originName.length > 14">
            <template slot="title">
              <div>
                <span v-if="record.term.termType == '人物'"
                  ><div>姓：{{ record.term.originFamilyName }}</div>
                  <div>名：{{ record.term.originGivenName }}</div></span
                >
                <span v-else>{{ record.term.originName }}</span>
              </div>
            </template>
            <div
              style="overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;"
            >
              <div>
                <span v-if="record.term.termType == '人物'"
                  ><div>姓：{{ record.term.originFamilyName }}</div>
                  <div>名：{{ record.term.originGivenName }}</div></span
                >
                <span v-else>{{ record.term.originName }}</span>
              </div>
            </div>
          </a-tooltip>
          <div v-else>
            <span v-if="record.term.termType == '人物'"
              ><div>姓：{{ record.term.originFamilyName }}</div>
              <div>名：{{ record.term.originGivenName }}</div></span
            >
            <span v-else>{{ record.term.originName }}</span>
          </div>
        </template>
        <template slot="chinese-name" slot-scope="text, record">
          <a-tooltip v-if="record.term && record.term.processedName && record.term.processedName.length > 14">
            <template slot="title">
              <div>
                <span v-if="record.term.termType == '人物'"
                  ><div>姓：{{ record.term.processedFamilyName }}</div>
                  <div>名：{{ record.term.processedGivenName }}</div></span
                >
                <span v-else>{{ record.term.processedName }}</span>
              </div>
            </template>
            <div
              style="overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;"
            >
              <div>
                <span v-if="record.term.termType == '人物'"
                  ><div>姓：{{ record.term.processedFamilyName }}</div>
                  <div>名：{{ record.term.processedGivenName }}</div></span
                >
                <span v-else>{{ record.term.processedName }}</span>
              </div>
            </div>
          </a-tooltip>
          <div v-else>
            <span v-if="record.term.termType == '人物'"
              ><div>姓：{{ record.term.processedFamilyName }}</div>
              <div>名：{{ record.term.processedGivenName }}</div></span
            >
            <span v-else>{{ record.term.processedName }}</span>
          </div>
        </template>
        <template slot="first-login-time" slot-scope="text, record">
          {{ record.term.debut }}
        </template>
        <template slot="phoneticStatus" slot-scope="text, record">
          <div v-if="record.term.phoneticStatus > 0 || record.term.phoneticStatus === 0">
            <span
              ><a-icon
                style="color:green"
                v-if="record.term.phoneticStatus == 1"
                type="check-circle"
                theme="filled"/><a-icon style="color:red" v-else type="close-circle" theme="filled"
            /></span>
            <span
              :style="record.term.phoneticStatus == 1 ? 'color:green;margin-left:5px' : 'color:red;margin-left:5px'"
              >{{
                record.term.phoneticStatus == 0
                  ? '未注音'
                  : record.term.phoneticStatus == 1
                  ? '已注音'
                  : record.term.phoneticStatus == 2
                  ? '姓未注音'
                  : record.term.phoneticStatus == 3
                  ? '名未注音'
                  : record.term.phoneticStatus == 4
                  ? '每次注音'
                  : record.term.phoneticStatus == 5
                  ? '不需要注音'
                  : ''
              }}</span
            >
          </div>
        </template>
        <template slot="phonetic" slot-scope="text, record">
          <div>
            <div>
              <span v-if="record.term.phoneticTextList && record.term.phoneticTextList.length">
                {{ record.term.phoneticTextList.map(p => p.value).join('') }}
              </span>
              <div v-if="record.term.termType == '人物'">
                <div v-if="record.term.phoneticFamilyNameTextList && record.term.phoneticFamilyNameTextList.length">
                  姓：{{ record.term.phoneticFamilyNameTextList.map(p => p.value).join('') }}
                </div>
                <div v-if="record.term.phoneticGivenNameTextList && record.term.phoneticGivenNameTextList.length">
                  名：{{ record.term.phoneticGivenNameTextList.map(p => p.value).join('') }}
                </div>
                <!-- <div v-if="record.term.phoneticNickNameTextList && record.term.phoneticNickNameTextList.length">
                  （{{ record.term.phoneticNickNameTextList.map(p => p.value).join('') }}）
                </div> -->
              </div>
            </div>
          </div>
        </template>
        <template slot="status" slot-scope="text, record">
          {{ record.term.status }}
        </template>
        <template slot="rejectReason" slot-scope="text, record">
          {{ record.term.rejectReason }}
        </template>
        <template slot="description" slot-scope="text, record">
          {{ record.term.description }}
        </template>
        <template slot="gender" slot-scope="text, record">
          <span style="padding-left:3px;">{{ record.term.gender }}</span>
        </template>
        <template slot="identity" slot-scope="text, record">
          <div style="padding-left:5px;">{{ record.term.identity }}</div>
        </template>
        <template slot="call" slot-scope="text, record">
          <div style="padding-left:5px;">{{ record.term.call }}</div>
        </template>
        <template slot="avatar" slot-scope="text, record">
          <div
            class="avatar"
            v-if="
              record.term.termTypeName == '主角' ||
                record.term.termTypeName == '配角' ||
                record.term.termTypeName == '路人'
            "
          >
            <img
              :src="record.term.subjectImgUrl[0]"
              alt="avatar"
              style="width:50px;height:50px"
              v-if="record.term.subjectImgUrl && record.term.subjectImgUrl[0]"
            />
            <img v-else src="./../../../assets/tx.png" style="width:50px;height:50px;border-radius: 50%" alt="" />
          </div>
        </template>
        <template slot="creator" slot-scope="text, record">
          <div style="display:flex;align-items:center">
            <div class="status-img">
              <img
                v-if="record.creator.avatar"
                :src="record.creator.avatar"
                style="width:35px;height:35px;border-radius: 50%;"
                alt=""
              />
              <img v-else src="./../../../assets/tx.png" style="width:35px;height:35px;border-radius: 50%;" alt="" />
            </div>
            <span>{{ record.creator.realname }}</span>
          </div>
        </template>
        <template slot="action" slot-scope="text, record">
          <span @click.stop>
            <a @click.stop="reject(record)" style="margin-right:15px" v-if="record.term.status.indexOf('已驳回') == -1"
              >驳回</a
            >
            <a v-if="record.term.status != '已确认'" @click.stop="send(record)">确认</a>
          </span>
        </template>
      </a-table>
      <terms-modal
        :productionId="parent.id"
        :parent="parent"
        :terms="terms"
        :open="drawerShow"
        :params="params"
        :cur-row="curRow"
        @changeCurrow="changeCurrow"
        :isForm="isNewForm"
        @closed="drawerClosed"
      ></terms-modal>
      <a-modal title="驳回理由" :visible="visible" @ok="handleOk" @cancel="handleCancel">
        <a-input v-model="rejectReason" placeholder="请输入驳回理由"></a-input>
      </a-modal>
    </a-spin>
  </div>
</template>

<script>
import Vue from 'vue'
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import TermsModal from './terms-modal-new'
import axios from 'axios'
import { getAction, postAction } from '../../../api/manage'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { USER_INFO } from '@/store/mutation-types'
import { Reject } from '@icon-park/vue'
export default {
  components: {
    TermsModal
  },
  mixins: [JeecgListMixin],
  props: {
    parent: {
      type: Object,
      default: {}
    },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      terms: [],
      params: null,
      isNew: false,
      showTermsModal: false,
      rejectRecord: null,
      visible: false,
      rejectReason: '',
      searchStatus: undefined,
      filters: {
        domain: 'TERMS'
      },
      selectedRowKeys: [],
      clickTermsRow: (record, index) => ({
        on: {
          click: () => {
            this.showTermsModal = true
            this.isNew = false
            this.curterm = record
          },
          contextmenu: event => {
            event.preventDefault()
            this.showContextMenu(event, record)
          }
        }
      }),
      columns: [
        {
          title: '分类',
          dataIndex: 'termsTemplate.templateName',
          width: 75,
          scopedSlots: { customRender: 'termsTemplate.templateName' }
        },
        {
          title: '原文名',
          dataIndex: 'koreaName',
          width: 100,
          scopedSlots: { customRender: 'korea-name' }
        },
        {
          title: '译文名',
          dataIndex: 'chineseName',
          width: 100,
          scopedSlots: { customRender: 'chinese-name' }
        },
        {
          title: '注音',
          dataIndex: 'phonetic',
          width: 100,
          scopedSlots: { customRender: 'phonetic' }
        },
        {
          title: '注音情况',
          width: 180,
          dataIndex: 'phoneticStatus',
          scopedSlots: { customRender: 'phoneticStatus' }
        },
        {
          title: '初次登场章节',
          dataIndex: 'firstLoginTime',
          width: 100,
          scopedSlots: { customRender: 'first-login-time' }
        },
        {
          title: '自称',
          dataIndex: 'call',
          width: 100,
          scopedSlots: { customRender: 'call' }
        },
        {
          title: '身份',
          dataIndex: 'identity',
          width: 100,
          scopedSlots: { customRender: 'identity' }
        },
        {
          title: '说明',
          dataIndex: 'description',
          width: 100,
          scopedSlots: { customRender: 'description' }
        },
        {
          title: '头像',
          width: 120,
          dataIndex: 'avatar',
          scopedSlots: { customRender: 'avatar' }
        },
        {
          title: '状态',
          dataIndex: 'status',
          width: 100,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '创建人',
          dataIndex: 'creator',
          width: 100,
          scopedSlots: { customRender: 'creator' }
        },
        {
          title: '操作',
          fixed: 'right',
          width: 120,
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ],
      url: {
        list: '/term/page?productionId=' + this.parent.id,
        deleteBatch: '/term/delete',
        exportXlsUrl: '/terms/exportXls?productionId=' + this.parent.id
      },
      loading: false,
      curterm: {},
      termTemplates: [],
      templateNameList: [],
      termTypeName: undefined,
      searchText: '',
      importLoading:false,
    }
  },
  methods: {
    changeCurrow(curRow) {
      this.curRow = curRow
    },
    beforeUpload() {
      return false
    },
    async handleImportExcel(info) {
      this.importLoading = true
      let formData = new FormData()
      formData.append('file', info.file)
      formData.append('productionId', this.parent.id)
      const res = await postAction('/term/import', formData)
      this.importLoading = false
      if (res.code == 200) {
        this.$message.success('导入成功')
        this.loadData()
      } else {
        this.$message.error(res.msg)
      }
    },
    importTerm() {},
    downloadTerm() {
      if (this.parent.departName == '日语部') {
        window.open(
          'https://mx2020.oss-cn-beijing.aliyuncs.com/template/%E6%97%A5%E8%AF%AD%E6%9C%AF%E8%AF%AD%E8%A1%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
        )
      } else {
        window.open(
          'https://mx2020.oss-cn-beijing.aliyuncs.com/template/%E9%9F%A9%E8%AF%AD%E6%9C%AF%E8%AF%AD%E8%A1%A8%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xlsx'
        )
      }
    },
    async getTypeList() {
      const res = await getAction('/term/type/page?productionId=' + this.parent.id)
      if (res.code == 200) {
        this.templateNameList = res.data.records.map(item => {
          return {
            type: item.termType.type,
            id: item.termType.id,
            name: item.termType.name
          }
        })
      }
    },
    searchQuery() {
      this.loadData()
    },
    async exportExcel() {
      const token = Vue.ls.get(ACCESS_TOKEN)
      const res = await axios.post(
        Vue.prototype.API_BASE_URL + '/term/export/',
        { productionId: this.parent.id },
        {
          headers: { 'X-Access-Token': token },
          responseType: 'blob'
        }
      )
      if (!res.data) return
      const blob = res.data
      const url = window.URL.createObjectURL(blob)

      // 以动态创建a标签进行下载
      const a = document.createElement('a')
      const fileName = this.parent.productionName + '-术语表'
      a.href = url
      a.download = fileName + '.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
      /*       const res = getAction('/term/export/',{productionId:this.parent.id})
      console.log(res,123) */
      /*       OMS.downLoadXlsx({
        dom: 'out-table', //获取表格dom，然后拿出数据，所以我需要知道解析哪一个表格
        name: '术语表', //excel名字
        ColumnWdth: that.columns.filter(item=>item.dataIndex!='action').map(item => {
          return item.width
        }) //每一列的宽度，需要直接指定，接受数字
      }) */
    },
    loadData(arg, id, callback) {
      if (!this.url.list) {
        this.$message.error('请设置url.list属性!')
        return
      }
      //加载数据 若传入参数1则加载第一页的内容
      if (arg === 1) {
        this.ipagination.current = 1
      }
      var params = this.getQueryParams() //查询条件
      if (this.id) params.id = this.id
      if (id) params.id = id
      this.loading = true
      params.current = params.pageNo
      params.size = params.pageSize
      params.status = this.searchStatus ? this.searchStatus.join(',') : ''
      params.termTypeName = this.termTypeName ?this.termTypeName.join(','): ''
      params.searchText = this.searchText
      this.params = params
      if (this.$route.query.tab) {
        params.onlySelectNoConfirm = this.$route.query.onlySelectNoConfirm
      }
      getAction(this.url.list, params).then(res => {
        console.log('JeecgListMixin loadData getAction over', { res })
        if (res.success) {
          this.selectedRowKeys = []
          this.selectionRows = []
          res.data = res.data || res.data
          //update-begin---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------
          this.dataSource = res.data.records || res.data
          if (res.data.total) {
            this.ipagination.total = res.data.total - 0
          } else {
            this.ipagination.total = 0
          }
          //update-end---author:zhangyafei    Date:20201118  for：适配不分页的数据列表------------

          // 字体预览需要往head里插入css样式
          if (this.isInserstCss) {
            batchStyleInsertHeader(this.dataSource)
          }
          this.$emit('loadData', this.dataSource)
          this.terms = []
          this.dataSource?.forEach(item => {
            this.terms.push({
              value: item.term.originName,
              target: item.term.processedName,
              id: item.term.id,
              type: item.term.termTypeName
            })
          })
          this.$nextTick(() => {
            if (this.$route.query.termId) {
              this.openDetail(this.dataSource.find(item => item.term.id == this.$route.query.termId))
              this.$route.query.termId = ''
            }
          })
        }
        if (res.code === 510) {
          this.$message.warning(res.message)
        }
        this.loading = false
      })
    },
    async sends() {
      let ids = this.selectionRows.map(record => record.term.id).join(',')
      const res = await postAction('/term/confirm?ids=' + ids + '&operation=DOWN&productionId=' + this.parent.id)
      if (res.code == 200) {
        this.$message.success('已确认')
        this.loadData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async rejects() {
      let ids = this.selectionRows.map(record => record.term.id).join(',')
      const res = await postAction(
        '/term/confirm?ids=' +
          ids +
          '&operation=UP&productionId=' +
          this.parent.id +
          '&rejectReason=' +
          this.rejectReason
      )
      if (res.code == 200) {
        this.$message.success('驳回成功')
        this.loadData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async send(record) {
      const res = await postAction(
        '/term/confirm?ids=' + record.term.id + '&operation=DOWN&productionId=' + this.parent.id
      )
      if (res.code == 200) {
        this.$message.success('已确认')
        this.loadData()
      } else {
        this.$message.error(res.msg)
      }
    },
    async handleOk() {
      const res = await postAction(
        '/term/confirm?ids=' +
          this.rejectRecord.term.id +
          '&operation=UP&productionId=' +
          this.parent.id +
          '&rejectReason=' +
          this.rejectReason
      )
      if (res.code == 200) {
        this.$message.success('驳回成功')
        this.loadData()
      } else {
        this.$message.error(res.msg)
      }
      this.visible = false
      this.rejectReason = ''
      this.rejectRecord = null
    },
    handleCancel() {
      this.visible = false
      this.rejectReason = ''
      this.rejectRecord = null
    },
    async reject(record) {
      this.rejectRecord = record
      this.visible = true
    },
    getFieldValueName(fieldName, record) {
      if (record.fields) {
        for (let idx = 0; idx < record.fields.length; idx++) {
          if (record.fields[idx].fieldName == fieldName) {
            return record.fields[idx].fieldValue
          }
        }
      }
      return ''
    },
    termsModalClosed() {
      this.showTermsModal = false
      this.loadData()
      this.getTermsTemplate()
    },
    newTerms() {
      this.showTermsModal = true
      this.isNew = true
      this.curterm = {}
    },
    getTermsTemplate() {
      getAction('/production/get_terms_template?pageSize=1000&id=' + this.parent.id).then(({ success, data }) => {
        if (success) {
          this.termTemplates = data.records
        }
      })
    },
    showContextMenu(e, record) {
      const userInfo = Vue.ls.get(USER_INFO)
      const stageNameList = userInfo.positions.map(item => item.name)
      let menuNode = document.getElementById('terms_menu')
      let verifyMenu = document.getElementById('verify_menu')
      let confirmMenu = document.getElementById('confirm_menu')
      if (stageNameList.indexOf('翻译') >= 0) {
        confirmMenu.style.display = 'initial'
      } else {
        confirmMenu.style.display = 'none'
      }
      if (stageNameList.indexOf('校对') >= 0) {
        verifyMenu.style.display = 'initial'
      } else {
        verifyMenu.style.display = 'none'
      }
      menuNode.record = record
      menuNode.style.display = 'initial'
      menuNode.style.position = 'fixed'
      menuNode.style.left = e.clientX + 'px'
      menuNode.style.top = e.clientY + 'px'
    },
    verifyTerms() {
      let menuNode = document.getElementById('terms_menu')
      menuNode.style.display = 'none'
      postAction('/terms/verify_terms?termsId=' + menuNode.record.termsId).then(res => {
        if (res.success) {
          this.loadData()
        }
      })
    },
    confirmTerms() {
      let menuNode = document.getElementById('terms_menu')
      menuNode.style.display = 'none'
      postAction('/terms/confirm_terms?termsId=' + menuNode.record.termsId).then(res => {
        if (res.success) {
          this.loadData()
        }
      })
    },
    hideContextMenu() {
      let menuNode = document.getElementById('terms_menu')
      menuNode.style.display = 'none'
    }
  },
  computed: {
    //token header
    tokenHeader() {
      let head = { 'X-Access-Token': Vue.ls.get(ACCESS_TOKEN) }
      let tenantid = Vue.ls.get(TENANT_ID)
      if (tenantid) {
        head['tenant_id'] = tenantid
      }
      return head
    }
  },
  created() {
    this.getTermsTemplate()
    this.getTypeList()
  }
}
</script>

<style lang="less" scoped>
.no_template {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  width: 100%;
}

.term {
  padding: 0.5rem !important;
  background-color: #fff;
  cursor: pointer;
}

.active_term {
  background-color: #5389df;
  color: #fff;
  border-radius: 5px;
}
.status-img {
  display: inline-block;
  position: relative;
  margin-right: 8px;
}
.avatar {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.term_header {
  display: flex;
  justify-content: space-between;
}

#terms_menu {
  display: none;
  position: absolute;
  width: 60px;
  background-color: white;
  box-shadow: 0 0 5px grey;
  border-radius: 3px;
  z-index: 9999999;
  button {
    width: 100%;
    background-color: white;
    border: none;
    margin: 0;
    padding: 10px;
  }

  button:hover {
    background-color: lightgray;
  }
}
</style>

<style>
.active_row {
  background-color: #fefe53;
}

.active_row:hover > td {
  background: #fefe53 !important;
}
</style>
